/* eslint-disable no-unreachable */
import React, { useEffect, useState } from "react";
import i18n from "i18n";
import { Tooltip } from "@material-ui/core";
import GetChannelImage from "helpers/GetChannelImage";
import DropMenu from "components/Menu/DropMenu";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getMesssageItemSelector } from "redux/selectors/cockpitSelectors";
import cockpitActions from "redux/actions/cockpitActions";
import engageActions from "redux/actions/engageActions";

/**
 * Get the correct mimeTypes for an attachment
 */
export const mimeTypeCompute = attachment => {
	let mimeType = "";

	if (attachment.mimeType) {
		mimeType = attachment.mimeType;
	}

	if (attachment.contentType) {
		mimeType = attachment.contentType;
	}

	if (mimeType === "") {
		if (attachment?.type === undefined) {
			mimeType = "application/pdf";
		} else if (attachment?.type?.indexOf("/") !== -1) {
			mimeType = attachment.type;
		} else {
			let fname = name?.toLowerCase();
			if (fname?.indexOf(".jpg") !== -1 || fname?.indexOf(".jpeg") !== -1) {
				mimeType = "image/jpeg";
			} else if (fname?.indexOf(".png") !== -1) {
				mimeType = "image/png";
			} else mimeType = "application/pdf";
		}
	}

	return mimeType;
};
/**
 * Get the file extension from a file name
 */
export const getFileExtension = fileName => {
	if (fileName) {
		const splited = fileName.split(".");
		if (splited.length > 1) {
			const ext = splited[splited.length - 1];
			return `.${ext.toUpperCase()}`;
		} else {
			return "";
		}
	} else {
		return "";
	}
};
/**
 * Get the file type from a mime type
 */
export const getFileType = mimeType => {
	if (mimeType) {
		let type = mimeType.split("/")[0];
		if (type !== "audio" && type !== "video" && type !== "image") {
			return "document";
		} else {
			return type;
		}
	} else {
		return "document";
	}
};
/**
 * Format bytes to a readable format
 */
export const formatBytes = (a, b = 2) => {
	if (0 === a) return "0 Bytes";
	const c = 0 > b ? 0 : b;
	const d = Math.floor(Math.log(a) / Math.log(1024));
	return (
		parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
		" " +
		["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
	);
};
/**
 * Manage file actions
 */
const FileActions = ({ customDownloadProcess, attachment, customOpen = null, mID }) => {
	const dispatch = useDispatch();
	const { actions } = useSelector(state => state.assistantconfig);
	const { selectedAssistantID, userStatus } = useSelector(state => state);
	const { selectedConversation } = useSelector(state => state.cockpit);
	const { channels } = useSelector(getMesssageItemSelector, shallowEqual);
	/**
	 * All availables actions
	 */
	const [filesActions, setFilesActions] = useState(
		/**
		 * Get all actions type "file" for attachments
		 */
		actions
			?.filter(action => action.type === "file" && action.active === true)
			.map(action => {
				let tempObject = {};
				let possibleChannelToEngage = channels.filter(a => a.engage === true && a.type === "ML");
				switch (action.action) {
					case "fowardAttachment":
						tempObject = {
							name: action.name,
							action: action.action,
							subMenu: {
								items: possibleChannelToEngage.map((channel, index) => {
									return {
										name: (
											<Tooltip title={channel.name}>
												<img
													src={GetChannelImage(possibleChannelToEngage, channel.code)}
													alt="Channel-logo"
													width="20"
													height="20"
													className="logo-channel"
												/>
											</Tooltip>
										),
										action: action.action,
										channel
									};
								})
							}
						};
						break;
					default:
						tempObject = action;
						break;
				}

				return tempObject;
			}) ?? []
	);
	/**
	 * Display picture in new window when user click on view button
	 */
	const handleDisplayPicture = attachment => {
		let { name = "Fichier / File : ", mimeType, url } = attachment;

		mimeType = ` type='${mimeType}'`;

		const css = `<html><head><title>${name}</title>`;
		let img =
			'<object style="width:100%;height:100%;margin:0 auto;" data="' + url + '"' + mimeType + "/>";
		let body = '<body style="width:100%;height:100%;margin:0 auto;">';

		if (mimeType.indexOf("pdf") === -1) {
			body =
				'<body style="margin: 0;transform: translate(-50%, -50%);left: 50%;top: 50%;position: absolute;">';
			img =
				'<object style="max-width:100vw;max-height:100vh;margin:0 auto;" data="' +
				url +
				'"' +
				mimeType +
				"/>";
		}
		const popup = window.open("");
		popup.document.write(css);
		popup.document.write(body);
		popup.document.write(img);
		popup.document.write("</body></html>");
		popup.document.close();
	};
	/**
	 * Download file (create fake download button)
	 */
	const handleDownload = async attachment => {
		let { name, url } = attachment;
		const image = await fetch(url);
		const imageBlog = await image.blob();
		const imageURL = URL.createObjectURL(imageBlog);

		const anchor = document.createElement("a");
		anchor.href = imageURL;
		anchor.download = name;

		document.body.appendChild(anchor);
		anchor.click();
	};
	/**
	 * Analyze attachment
	 */
	const handleAnalyzeAttachment = (attachment, actionType) => {
		let { url, name } = attachment;
		let analyzeImageObject = {
			action: actionType,
			payload: {
				assistantID: selectedAssistantID,
				mID: mID,
				fID: selectedConversation.header.fID,
				agentuID: userStatus.auth.user._id,
				params: {
					url: url,
					name: name
				}
			}
		};

		dispatch(cockpitActions.callFileAction(analyzeImageObject));
	};

	/**
	 * Forward attachment
	 */
	const handleFowardAttachment = (data, attachment) => {
		const initialDraft = {
			attachments: [attachment]
		};
		dispatch(engageActions.createEngageConversation(data.channel.code, initialDraft));
	};
	/**
	 * Handle action when user click on action in DropMenu
	 */
	const handleFileToCaseMessage = (data, attachment) => {
		let fileToCaseMessage = {
			action: "fileToCase",
			payload: {
				assistantID: selectedAssistantID,
				mID: mID,
				fID: selectedConversation.header.fID,
				agentuID: userStatus.auth.user._id,
				params: {
					attachmentName: attachment.name
				}
			}
		};

		dispatch(cockpitActions.callMessageAction(fileToCaseMessage));
	};

	/**
	 * Analyze attachment for lg warranty spare part request
	 */
	const handleAnalyseLgWarrantySparePartRequest = attachment => {
		let { url, name } = attachment;
		let action = {
			action: "analyseLgWarrantySparePartRequest",
			payload: {
				assistantID: selectedAssistantID,
				mID: mID,
				fID: selectedConversation.header.fID,
				agentuID: userStatus.auth.user._id,
				params: {
					url: url,
					name: name
				}
			}
		};

		dispatch(cockpitActions.callFileAction(action));
	};

	/**
	 * Analyse document LLM
	 * @param {*} data
	 * @param {*} attachment
	 */
	const analyseDocumentLlm = (data, attachment) => {
		dispatch(
			cockpitActions.launchActionFrontV2("analyseDocumentLlm", {
				header: {
					assistantID: selectedConversation.header.assistantID,
					fID: selectedConversation.header.fID
				},
				payload: {
					analyseDocumentLlmCode: data.config.code,
					attachments: [attachment]
				}
			})
		);
	};

	/**
	 * Handle action when user click on action in DropMenu
	 */
	const handleFileToClipboard = attachment => {
		let copyLocal = JSON.parse(localStorage.getItem("presse-papier")) || [];

		copyLocal.push({
			fID: selectedConversation.header.fID,
			name: attachment?.name,
			url: attachment?.url,
			assistantID: selectedAssistantID,
			type: "image"
		});

		localStorage.setItem("presse-papier", JSON.stringify(copyLocal));
		dispatch(cockpitActions.setCopyClipboard());
	};

	const handleDropMenuActions = (data, attachment) => {
		switch (data.action) {
			case "viewFile":
				handleDisplayPicture(attachment);
				break;
			case "downloadFile":
				if (!customDownloadProcess) {
					/**
					 * Classic download
					 */
					handleDownload(attachment);
				} else {
					/**
					 * Special case for Cold to transform url to signed url
					 */
					customDownloadProcess(attachment.url);
				}
				break;
			case "analyzeWaterMeter":
				handleAnalyzeAttachment(attachment, "analyzeWaterMeter");
				break;
			case "analyzeHeatPump":
				handleAnalyzeAttachment(attachment, "analyzeHeatPump");
				break;
			case "fowardAttachment":
				handleFowardAttachment(data, attachment);
				break;
			case "fileToCase":
				handleFileToCaseMessage(data, attachment);
				break;
			case "copyFile":
				handleFileToClipboard(attachment);
				break;
			case "analyseLgWarrantySparePartRequest":
				handleAnalyseLgWarrantySparePartRequest(attachment);
				break;
			case "analyseDocumentLlm":
				analyseDocumentLlm(data, attachment);
				break;
			default:
				break;
		}
	};
	return (
		<DropMenu
			handleAction={data => {
				handleDropMenuActions(data, attachment);
			}}
			items={filesActions}
			customOpen={customOpen}
		/>
	);
};

export default FileActions;
